.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  color: #212529;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  svg {
    vertical-align: middle;
  }

  &.primary {
    color: var(--btn-primary-txt);
    background-color: #623686;
    border-color: #623686;
    &:hover {
      color: var(--btn-primary-txt);
      background-color: rgba(109, 32, 197, 0.8431372549019608);
      border-color: rgba(109, 32, 197, 0.8431372549019608);
    }
  }
}